/* eslint-disable react/jsx-pascal-case */
import "./Nosotros.scss"
import { useState} from 'react';
// Secciones componentes de Nosotros
import { NSection_Description } from "../../UI/organisms/N_Section_Description/NSectionDescription";
import { N_Section_1 } from "../../UI/organisms/N_Section_1/N_Section_1";
import { N_Section_2 } from "../../UI/organisms/N_Section_2/N_Section_2";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { PageLoading } from "../../../Loanding";

// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const Nosotros = () => {
    const [loading, setLoading] = useState(null);

    if (loading) return <PageLoading />;
    return ( 
        <div className="main-home">
            <div className="ellipse-div-1"/>
            <FadeInSection>
                <NSection_Description setLoading={setLoading}/>
            </FadeInSection>
            <FadeInSection>
                <N_Section_2 setLoading={setLoading}/>
            </FadeInSection>
            <FadeInSection>
                <N_Section_1 setLoading={setLoading}/>
            </FadeInSection>
        </div>
    );
}
