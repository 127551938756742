import "./N_Section_2.scss";
import { useEffect, useState } from "react";
import { db } from '../../../../firebase/config';
import { getDoc, doc } from 'firebase/firestore';


export const N_Section_2 = ({setLoading}) => {
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'qDlqwxRcQliL8C7u7AyZ');
        getDoc(specificDocRef)
            .then((document) => {
                if (document.exists) {
                    const data = document.data();
                    if (isMounted && data) {
                        setInfo(data);
                    }
                } else {
                    console.log("No such document!");
                }
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                    setLoading(false);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });

        return () => { isMounted = false; };
    }, [setLoading]);

    if (error) return <p>Error: {error.message || "An error occurred"}</p>;
    return (
        <section className="section-contect">
            <div className="section-2N-contect-container">
                <div className="section-2N-img">
                    <img alt={info.title} src={info.img} className="img-0N"/>
                </div>
                <div className="section-2N-title">
                    <h1 className="title-page-nosotros" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                    <p className="subtitle-page-nosotros"> 
                        <span className="span1">Promover</span>
                        <span className="span2">bienestar, productividad, eficiencia</span> y
                        <span className="span3">cumplimiento legal</span>, habilitando espacios de trabajo de
                        <span className="span2">manera simple</span>,
                        <span className="span1">integral,</span> y según las
                        <span className="span3">necesidades</span> de los clientes.
                    </p>
                </div>
            </div>
        </section>
    );
}
