import "./Reports.scss";
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";

// Función para formatear el timestamp a una fecha y hora legible
const formatDate = (timestamp) => {
    const date = timestamp.toDate(); // Si estás usando Firebase Timestamp
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

// Función para formatear balance como "n meses"
const formatBalance = (balance) => {
    return `${balance} meses`;
};

export const ReportAssignedProducts = ({ info }) => {
    const selectedFields = ["sku", "name", "orden_id", "balance", "date_create"];

    const flattenOrderData = (order) => {
        const flatData = [];
        if (order.order_product && order.order_product.length > 0) {
            order.order_product.forEach(product => {
                const flattenedProduct = { ...order };
                flattenedProduct.name = product.name;
                flattenedProduct.sku = product.sku;
                flatData.push(flattenedProduct);
            });
        } else {
            flatData.push(order);
        }
        return flatData;
    };

    const generateCSVData = () => {
        return info.flatMap(order => {
            const flattenedOrders = flattenOrderData(order);
            return flattenedOrders.map(flattenedOrder => {
                const filteredOrder = {};
                selectedFields.forEach(field => {
                    if (field === "date_create" && flattenedOrder[field]) {
                        filteredOrder[field] = formatDate(flattenedOrder[field]);
                    } else if (field === "balance" && flattenedOrder[field]) {
                        filteredOrder[field] = formatBalance(flattenedOrder[field]);
                    } else {
                        filteredOrder[field] = flattenedOrder[field];
                    }
                });
                return filteredOrder;
            });
        });
    };

    return (
        <div className="buttom-orden-container">
            {info.length !== 0 ? (
                <CSVLink data={generateCSVData()} className="button-new">
                    <FiDownload /> Descargar CSV
                </CSVLink>
            ) : (
                <div></div>
            )}
        </div>
    );
};
