import "./Section_2.scss"
import { SliderArt } from "../../molecules/SliderArt/SliderArt";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";


export const Section2 = ({ settings, setIsOpenR, setLoading, setError}) => {
    const [info, setInfo] = useState([]);
    const location = useLocation(); 

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'UUkZeu0VFriqQvRfyzbY');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);


    const navigate = useNavigate();
    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
        navigate( `${info.button.route}`);
    };
    return (
        <section className="section-contect">
            <div className="section-2-contect">
                <SliderArt slider={info.slider} settings={settings}/>
                <div className="section-2-title">
                    <h1 className="title-page-1" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                    <p className="subtitle-page-1" dangerouslySetInnerHTML={{ __html: info.subtitle }}></p>
                    <div className="container-button-nav">
                        {info.button && 
                        location.pathname !== "/index.php/invitacion-2/" && 
                        location.pathname !== "/aliados/treli" && 
                        location.pathname !== "/aliados/siigo" && 
                        location.pathname !== "/webinars/treli" && 
                            (
                                <button className="button-tipe2" onClick={toggleRe}>
                                    {info.button.title}
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
