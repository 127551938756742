import "./NavMenuAdmin.scss";
import { NavLink } from "react-router-dom";
import { BiArchive, BiBarChartSquare, BiBarcode, BiBasket, BiBook, BiCabinet, BiChevronDown, BiChevronUp, BiCollection, BiFoodMenu, BiGroup, BiHomeAlt2, BiLayer, BiMessageSquareDetail, BiUserPlus, BiUserVoice } from "react-icons/bi";
// import { IoDocumentAttachOutline } from "react-icons/io5";
import { FiCheckCircle, FiCodesandbox, FiFeather, FiSettings, FiShoppingBag } from "react-icons/fi";
import { LuFileStack } from "react-icons/lu";
import { MdOutlineQuiz } from "react-icons/md";
import { useState } from "react";
import { TbTools } from "react-icons/tb";
// import { RiPagesLine } from "react-icons/ri";
import { MdEventNote } from "react-icons/md";
import { CgBrowser , CgAlbum} from "react-icons/cg";
import { LuBookUp2, LuWarehouse } from "react-icons/lu";

export  const NavMenuAdmin = ({rol, setIsOpena }) => {
    const [opena, setOpena] = useState(false);
    const toggleSubMenu = () => {
        setOpena(!opena);
        setOpenaE(false);
        setOpenaK(false);
        setOpenaP(false);
        setOpenaI(false);
        setOpeStartco(false);
    };
    const [openaE, setOpenaE] = useState(false);
    const toggleSubMenuE = () => {
        setOpenaE(!openaE);
        setOpena(false);
        setOpenaK(false);
        setOpenaP(false);
        setOpenaI(false);
        setOpeStartco(false);
    };
    const [openaK, setOpenaK] = useState(false);
    const toggleSubMenuK = () => {
        setOpenaK(!openaK);
        setOpena(false);
        setOpenaE(false);
        setOpenaP(false);
        setOpenaI(false);
        setOpeStartco(false);
    };
    const [openaP, setOpenaP] = useState(false);
    const toggleSubMenuP = () => {
        setOpenaP(!openaP);
        setOpena(false);
        setOpenaE(false);
        setOpenaK(false);
        setOpenaI(false);
        setOpeStartco(false);
    };

    const [openaOrden, setOpenaOrden] = useState(false);
    const toggleSubOrden = () => {
        setOpenaOrden(!openaOrden);
        setOpena(false);
        setOpenaE(false);
        setOpenaK(false);
        setOpenaI(false);
        setOpeStartco(false);
    }

    const [openaI, setOpenaI] = useState(false);
    const toggleSubMenuI = () => {
        setOpenaI(!openaI);
        setOpenaP(false);
        setOpena(false);
        setOpenaE(false);
        setOpenaK(false);
        setOpeStartco(false);
    };

    const [opeStartco, setOpeStartco] = useState(false);
    const toggleStartco = () => {
        setOpenaP(false);
        setOpeStartco(!opeStartco)
        setOpena(false);
        setOpenaE(false);
        setOpenaI(false);
        setOpenaK(false);
    };

    const closenav = () => {
        setOpenaP(false);
        setOpena(false);
        setOpenaE(false);
        setOpenaK(false);
        setOpenaP(false);
        setOpenaI(false);
        setOpeStartco(false);
        if (window.innerWidth < 550) {
            setIsOpena(false);
        }
    };
    return (
        <nav className="nav-menu-left">
            <NavLink onClick={closenav} to="/" className="button-menu-left" activeClassName="active-link"><BiHomeAlt2 /><p>Home </p></NavLink>
            {(rol === "AA-003")&&(
                <>
                    <NavLink onClick={closenav} to="/kits/lista" className="button-menu-left" activeClassName="active-link"><CgAlbum /> <p>Lista Kits</p></NavLink>
                    <NavLink onClick={closenav} to="/usuarios" className="button-menu-left" activeClassName="active-link"><BiGroup /> <p>Colaboradores</p></NavLink>
                    
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={toggleSubMenuI}>
                            <div className="sub-menu">
                                <CgBrowser /> 
                                <p>Reportes</p>
                            </div>
                            <div className="sub-icon-menu">
                                { !openaI ? (<BiChevronDown /> ) : (<BiChevronUp />)}
                            </div>
                        </button>
                        {openaI && (
                            <div className="submenu-left">
                                <NavLink onClick={closenav} to="/reportes" className="button-submenu-left" activeClassName="active-link"><LuBookUp2 /> <p>Creador informes</p></NavLink>
                                <NavLink onClick={closenav} to="/inventario/stock" className="button-submenu-left" activeClassName="active-link"><LuWarehouse /> <p>Stock por asignar</p></NavLink>
                                <NavLink onClick={closenav} to="/inventario/asignado" className="button-submenu-left" activeClassName="active-link"><FiShoppingBag /> <p>Stock asignado</p></NavLink>
                            </div>
                        )}
                    </div>
                </>
            )}
            {(rol === "AA-002" ) && (
                <>
                    <NavLink onClick={closenav} to="/open/invitations" className="button-menu-left" activeClassName="active-link"><MdOutlineQuiz /> <p>Encuestas</p></NavLink>
                </>
            )}
            {(rol === 'AA-001' 
            || rol === 'SA-001'
            || rol === 'SA-002'
            || rol === 'SA-003'
            || rol === 'SA-004'
            || rol === 'SA-005') 
            && (
                <>
                    <div>
                        <button className="button-submenu  button-equipo" activeClassName="active-link" onClick={toggleSubMenu}>
                            <div className="sub-menu">
                                <BiGroup />
                                <p>Equipo</p>
                            </div>
                            <div className="sub-icon-menu">
                                { !opena ? (<BiChevronDown /> ) : (<BiChevronUp />)}
                            </div>
                        </button>
                        {opena && (
                            <div className="submenu-left">
                                <NavLink onClick={closenav} to="/usuarios" className="button-submenu-left" activeClassName="active-link"><BiUserPlus/> <p>Usuarios</p></NavLink>
                                <NavLink onClick={closenav} to="/usuarios/grupos" className="button-submenu-left" activeClassName="active-link"><BiLayer /> <p>Grupos</p></NavLink>
                                {/* <NavLink onClick={closenav} to="/usuarios/status" className="button-submenu-active-pc" activeClassName="active-link"><IoDocumentAttachOutline /> <p>Informe</p></NavLink> */}
                            </div>
                        )}
                    </div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={toggleSubMenuE}>
                            <div className="sub-menu">
                                <MdOutlineQuiz />
                                <p>Encuestas</p>
                            </div>
                            <div className="sub-icon-menu">
                                { !openaE ? (<BiChevronDown /> ) : (<BiChevronUp />)}
                            </div>
                        </button>
                        {openaE && (
                            <div className="submenu-left">
                                <NavLink onClick={closenav} to="/encuestas/send" className="button-submenu-left" activeClassName="active-link"><BiUserVoice /><p>Asignar encuesta</p></NavLink>
                                <NavLink onClick={closenav} to="/encuesta/lista" className="button-submenu-left" activeClassName="active-link"><LuFileStack /><p>Lista encuestas</p></NavLink>
                            </div>
                        )}
                    </div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={toggleSubMenuK}>
                            <div className="sub-menu">
                                <BiArchive />
                                <p>Kits</p>
                            </div>
                            <div className="sub-icon-menu">
                                { !openaK ? (<BiChevronDown /> ) : (<BiChevronUp />)}
                            </div>
                        </button>
                        {openaK && (
                            <div className="submenu-left">
                                <NavLink onClick={closenav} to="/kits/send" className="button-submenu-left" activeClassName="active-link"><BiUserPlus /> <p>Asignación Kits</p></NavLink>
                                <NavLink onClick={closenav} to="/kits/lista" className="button-submenu-left" activeClassName="active-link"><BiCabinet /><p>Lista asignados</p></NavLink>
                                <NavLink onClick={closenav} to="/equipos" className="button-submenu-left" activeClassName="active-link" ><TbTools /><p>Mis equipos</p></NavLink>
                            </div>
                        )}
                    </div>
                </>
            )}
            {rol === 'US-001' && (
                <>
                    <NavLink onClick={closenav} to="/encuesta/lista" className="button-menu-left" activeClassName="active-link"><LuFileStack /><p>Encuestas</p></NavLink>
                    <NavLink onClick={closenav} to="/kits/lista" className="button-menu-left" activeClassName="active-link"><BiCabinet /><p>Asignación</p></NavLink>
                    <NavLink onClick={closenav} to="/equipos" className="button-menu-left" activeClassName="active-link" ><TbTools /><p>Mis equipos</p></NavLink>
                </>
            )}
            <NavLink onClick={closenav} to="/soporte" className="button-menu-left" activeClassName="active-link"><BiMessageSquareDetail /> <p>Soporte</p></NavLink>
            {(rol === 'AA-001' || rol === 'AA-003' )&& (<NavLink onClick={closenav} to="/marca/date" className="button-menu-left" activeClassName="active-link"><FiSettings /><p>Marca</p></NavLink>)}
            {( rol === 'SA-001'
            || rol === 'SA-002'
            || rol === 'SA-003'
            || rol === 'SA-004'
            || rol === 'SA-005')  && (
                <>
                    <div><p className="title-menu">Administrador Web</p></div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={toggleSubOrden}>
                            <div className="sub-menu">
                                <FiCodesandbox />
                                <p>Logistica</p>
                            </div>
                            { !openaOrden ? (<BiChevronDown /> ) : (<BiChevronUp />)}
                        </button>
                        {openaOrden && (
                            <div className="submenu-left">
                                <NavLink onClick={closenav} to="/order" className="button-submenu-left" activeClassName="active-link"><FiCheckCircle /> <p>Lista aprobación</p></NavLink>
                                <NavLink onClick={closenav} to="/order/update" className="button-submenu-left" activeClassName="active-link"><FiFeather /> <p>Fecha entrega</p></NavLink>
                            </div>
                        )}
                    </div>
                    
                    
                    {/* <NavLink onClick={closenav} to="/blogs/lista" className="button-menu-left" activeClassName="active-link"><RiPagesLine /><p>Blogs</p></NavLink> */}
                    <NavLink onClick={closenav} to="/marca/lista" className="button-menu-left" activeClassName="active-link"><FiSettings /><p>Sellers</p></NavLink>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={toggleSubMenuP}>
                            <div className="sub-menu">
                                <BiBarcode />
                                <p>Productos</p>
                            </div>
                            { !openaP ? (<BiChevronDown /> ) : (<BiChevronUp />)}
                        </button>
                        {openaP && (
                            <div className="submenu-left">
                                <NavLink onClick={closenav} to="/productos"  className="button-submenu-left" activeClassName="active-link" ><BiBasket /><p>Base</p></NavLink>
                                <NavLink onClick={closenav} to="/productos/categoria"  className="button-submenu-left" activeClassName="active-link" ><BiBook /><p>Categorias</p></NavLink>
                                <NavLink onClick={closenav} to="/productos/asignados"  className="button-submenu-left" activeClassName="active-link" ><BiFoodMenu /><p>Asignados</p></NavLink>
                            </div>
                        )}
                    </div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={toggleStartco}>
                            <div className="sub-menu">
                                <MdEventNote /><p>Registros</p>
                            </div>
                            { !openaE ? (<BiChevronDown /> ) : (<BiChevronUp />)}
                        </button>
                        {opeStartco && (
                            <div className="submenu-left">
                                <NavLink onClick={closenav} to="/registros/SHOFI"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> SHOFI web</p></NavLink>
                                <NavLink onClick={closenav} to="/registros/Siigo"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Siigo</p></NavLink>
                                <NavLink onClick={closenav} to="/registros/Demo"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Demo</p></NavLink>
                                <NavLink onClick={closenav} to="/startco/inversionista"className="button-submenu-left"  activeClassName="active-link"><BiBarChartSquare /><p> Sala SHOFI</p></NavLink>
                                <NavLink onClick={closenav} to="/startco/spaces"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Cowork</p></NavLink>
                                <NavLink onClick={closenav} to="/startco/sales"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Juntas</p></NavLink>
                            </div>
                        )}
                    </div>
                </>
            )}
        </nav>
    );
}
