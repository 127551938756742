import "./ItenProducAsign.scss";


export const ItenProducAsign = ({date_create, name, orden_id, sku, img, balance}) => {
    // Fecha de creacion de users
    let fechaFormateada = "";
    if(date_create) {
        try {
            const fecha = date_create.toDate(); 
            fechaFormateada = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }


    return (
        <div className="item-product-assing">
            <div className="avatar">
                {img &&
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                }
            </div>
            <p className="name-user">{name}</p>
            <p className="email">{sku}</p>
            {/* <p className="phone">{compañia}</p> */}
            <div className="phone">
                {orden_id}
            </div>
            <div className="dates-mes">
                {balance} Meses
            </div>
            <p className="date">{fechaFormateada}</p>
        </div>
    );
}
