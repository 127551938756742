import "./Balance.scss";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { InfoLoading } from "../../../../Loanding";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ReportBalance } from "../reports/ReportBalance";
import { debounce } from "lodash";

export const Balance = ({idc}) => {
    const itemsPerPage = 20;
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState([]);
    const [totalBalance, setTotalBalance] = useState(0); 
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]); // Fecha de hoy por defecto
    const [minStartDate, setMinStartDate] = useState('');
    const [statusFilter, setStatusFilter] = useState('todos'); // 'negativo', 'positivo', 'todos'
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterByStatus = (items) => {
        switch (statusFilter) {
            case 'positivo':
                return items.filter(item => item.status_balance === true);
            case 'negativo':
                return items.filter(item => item.status_balance === false);
            default:
                return items;
        }
    };
    
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!idc) throw new Error("idc es indefinido");
                const data = collection(db, 'balance_service');
                const q = query(data, where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                if (isMounted) {
                    let items = querySnapshot.docs.map(doc => {
                        const data = doc.data();
                        const date = data.date ? data.date.toDate().toISOString().split('T')[0] : null;
                        return {
                            id: doc.id,
                            ...data,
                            date_print: date,
                        };
                    });
                    
                    // Ordenar los elementos por fecha de nuevo a viejo
                    const sortedItems = items.sort((a, b) => {
                        if (!a.date && !b.date) return 0;
                        if (!a.date) return 1;
                        if (!b.date) return -1;
                        return b.date.toDate() - a.date.toDate();
                    });
                    // Establecer la fecha mínima y la fecha de inicio solo si no ha sido establecida aún
                    if (sortedItems.length > 0 && !startDate) {
                        const firstDate = sortedItems[sortedItems.length - 1].date_print; // La fecha más antigua
                        setStartDate(firstDate);
                        setMinStartDate(firstDate);
                    }
                    
                    // Filtrar los elementos por status y fechas seleccionadas
                    let filteredItems = filterByStatus(sortedItems);
                    if (startDate && endDate) {
                        const startTimestamp = new Date(startDate).getTime();
                        const endTimestamp = new Date(endDate).getTime();
                        filteredItems = filteredItems.filter(order => {
                            const orderDate = new Date(order.date_print).getTime();
                            return orderDate >= startTimestamp && orderDate <= endTimestamp;
                        });
                    }
    
                    setInfo(filteredItems);
                    const total = sortedItems.reduce((acc, item) => acc + (item.balance || 0), 0);
                    setTotalBalance(total);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
    
        fetchInfo();
        return () => {
            isMounted = false;
        };
    }, [endDate, filterByStatus, idc, startDate, statusFilter]); // Incluye statusFilter como dependencia
    

    const formatDate = (firebaseTimestamp) => {
        try {
            const date = firebaseTimestamp.toDate();
            return `${date.toLocaleDateString('es-ES')} ${date.toLocaleTimeString('es-ES')}`;
        } catch(e) {
            console.error("Error al convertir fecha:", e);
            return "Fecha inválida";
        }
    };

    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        handler();
        return () => {
            handler.cancel();
        };
    }, [searchTerm]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const balanceItems = useMemo(() => 
        info.filter(item => 
            item.serial.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            item.name_user.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        ), [debouncedSearchTerm, info]
    );
    const numberOfPages = Math.ceil( balanceItems.length / itemsPerPage);
    const displayItems = balanceItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <div className="list-balance">
            <div className="contect-list-balance">
                <div className="encabezado-list-balance">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar operación..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <section className="date-contect-balance">
                        <div className="input-date-orden">
                            <label>Inicio:</label>
                            <input 
                                type="date" 
                                value={startDate} 
                                onChange={e => setStartDate(e.target.value)} 
                                min={minStartDate} // Establecer la fecha mínima como la del primer registro
                            />
                        </div>
                        <div className="input-date-orden">
                            <label>Final:</label>
                            <input 
                                type="date" 
                                value={endDate} 
                                onChange={e => setEndDate(e.target.value)} 
                                max={new Date().toISOString().split('T')[0]} // Limitar la fecha máxima a hoy
                            />
                        </div>
                        <div className="input-date-orden">
                            <label>Estado:</label>
                            <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                                <option value="todos">Todos</option>
                                <option value="positivo">Positivo</option>
                                <option value="negativo">Negativo</option>
                            </select>
                        </div>
                    </section>
                    <ReportBalance 
                       info={info}
                    />
                </div>
                <div className="list-balance">
                    <div className="title-balance-list">
                        <p className="title-balance">Total: {totalBalance}</p>
                        <p>Créditos</p>
                    </div>
                    {displayItems.length!==0
                        ?<>
                            {displayItems.map(inf => (
                                <Link key={inf.id} className={`item-movi ${inf.status_balance ? 'siigo-true' : 'siigo-false'}`} to={`${inf.status_balance ? '' : `/usuarios/${inf.idu}`}`}>
                                    <div className="dates-info-balance">
                                        <p>{inf.name_user}</p>
                                        <p>{formatDate(inf.date)}</p>
                                        <p>{inf.serial}</p>
                                    </div>
                                    <h2 className="info-balance">
                                        {inf.balance}
                                    </h2>
                                </Link>            
                            ))}
                        </>
                        :<>
                            <div className="windons-quiz-init">
                                <div className="quiz-init-img">
                                    <h2 className="title-shofi-loanding">No hay registros...</h2>
                                </div>
                            </div>
                        </>
                    }
                        
                </div>
                <div className="pagination-numb">
                    <Stack spacing={2}>
                        <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                    </Stack>
                </div>
            </div>
        </div>
    );
};
