import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenProducAsign } from '../../molecules/ItenProducAsign/ItenProducAsign';
import { ReportAssignedProducts } from '../../../page/private/reports/ReportAssignedProducts';


export const ListStockAsign = ({ setSearchTerm, startDate, endDate, setStartDate, setEndDate, searchTerm ,info,  handleChange ,numberOfPages, displayItems, currentPage}) => {

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        if (new Date(endDate) < new Date(e.target.value)) {
            setEndDate('');
        }
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    return (
        <div className="list-users">
            <div className="contect-list-users">
                <div className="encabezado-users-list">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar usuarios..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <ReportAssignedProducts info={info}/>
                </div>
                {info && (
                    <>
                        <div className="title-user-list">
                            <p>img</p>
                            <div className="filter">
                                Descripción
                            </div>
                            <p>
                                SKU
                            </p>
                            <div className="filter">
                               orden ID
                            </div>
                            <div className="filter">
                                Saldo
                            </div>
                            <div className="filter">
                                <input 
                                    type="date" 
                                    value={startDate} 
                                    onChange={handleStartDateChange} 
                                />
                                <input 
                                    type="date" 
                                    value={endDate} 
                                    onChange={handleEndDateChange} 
                                    min={startDate}
                                />
                            </div>
                        </div>
                            {displayItems.length!==0
                                ?<>
                                    {Array.isArray(displayItems) && displayItems.length > 0 ? (
                                    <div className="list">
                                        {displayItems.map(users => (
                                            <ItenProducAsign key={users.id} {...users} />
                                        ))}
                                    </div>
                                    ) : (
                                        <p>No hay elementos para mostrar.</p>
                                    )}
                                    <div className="pagination-numb">
                                        <Stack spacing={2}>
                                            <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                                        </Stack>
                                    </div>
                                </>
                            :<>
                                <div className="windons-quiz-init">
                                    <div className="quiz-init-img">
                                        <h2 className="title-shofi-loanding">No hay usuarios con estas características...</h2>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                )}
            </div>
        </div>
    );
}