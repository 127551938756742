/* eslint-disable react/jsx-no-target-blank */
import "./Footer.scss"
import { ImFacebook, ImLinkedin2 } from "react-icons/im";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Link} from 'react-router-dom';
import { useState } from "react";


export const Footer = ({setIsLogin, setIsOpenR}) => {
    const [opef, setOpef] = useState(false);
    const toggleFooter = () => {
        setOpef(!opef);
    };
    const toggleLogin = () => {
        setIsLogin(prevIsFoo=> !prevIsFoo);
    };
    const toggleForm= () => {
        setIsOpenR(prevIsLogin=> !prevIsLogin);
    };
    return (
        <footer className="footer">
            <section className="container-footer">
                <article className="footer-brand">
                    <img className="brand" alt="Shofi Footer" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI_Blanco.png?alt=media&token=7ab201fe-9454-4f92-9da4-2e81d6879838"/>
                    <h2 className="title-brand-footer">Tu aliado<br/>experto para el<br/>trabajo en casa</h2>
                </article>
                <article className="form-footer">
                    <nav className='site-map'>
                        <Link to={"/politicas_condiciones"} className='button-footer' >Aviso de privacidad</Link>
                        <Link to={"tratamiento_de_datos_personales"} className='button-footer' >Política de tratamiento datos personales</Link>
                        <Link to={"/aliados/siigo"} className='button-footer' >Aliados Siigo</Link>
                        <button onClick={toggleForm} className='button-footer' >Servicio de contacto</button>
                        <button onClick={toggleLogin} className='button-footer' >Login</button>
                    </nav>
                </article>
                <article className="footer-rrss">
                    <p className="title-rrss">Encuéntranos en<br/>redes sociales:</p>
                    <nav className='redes-menu'>
                        <a href='https://www.linkedin.com/company/shofi/' className='redes' target="_blank"><ImLinkedin2 /></a>
                        <a href='https://www.facebook.com/shoficolombia' className='redes' target="_blank"><ImFacebook /></a>
                        <a href='https://www.instagram.com/shofi_colombia/' className='redes' target="_blank"><FaInstagram /></a>
                        <a href='https://api.whatsapp.com/send/?phone=573058080419&text=%C2%A1Hola%21+Me+gustar%C3%ADa+obtener+m%C3%A1s+informaci%C3%B3n.&type=phone_number&app_absent=0' className='redes' target="_blank"><FaWhatsapp /></a>
                    </nav>
                    <div className="nav-footer">
                    <button className="title-rrss" onClick={toggleFooter}>Enlaces de interés</button>
                        {opef && (
                            <nav className='site-map'>
                                <Link to={"/politicas_condiciones"} className='button-footer' >Aviso de privacidad</Link>
                                <Link to={"tratamiento_de_datos_personales"}  className='button-footer' >Política de tratamiento datos personales</Link>
                                <Link to={"/aliados/siigo"} className='button-footer' >Aliados Siigo</Link>
                                <button onClick={toggleForm} className='button-footer'>Servicio de contacto</button>
                                <button onClick={toggleLogin} className='button-footer'>Login plataforma</button>
                            </nav>
                        )}
                    </div>
                </article>
            </section>
            <section className="copy-footer">
                <p className="copy">Copyright ©2024 SHOFI.</p>
            </section>
        </footer>
    );
}
