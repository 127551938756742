import "./Kits.scss";
import { useEffect, useMemo, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { toast } from "react-toastify";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { ListStockAsign } from "../../../UI/organisms/ListStockAsign/ListStockAsign";
import { debounce } from "lodash";

export const StockAssignedProducts = ({idc, compañia}) => {
    const itemsPerPage = 20;
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOrderStatus, setFilterOrderStatus] = useState(''); 
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const contractMonths = 24;

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setCurrentPage(1); // Resetear a la primera página al cambiar filtros
        const fetchUsers = async () => {
            try {
                const usersCollectionRef = query(collection(db, "products-assignment"), where("idc", "==", idc));
                const querySnapshot = await getDocs(usersCollectionRef);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    date_delivery: doc.data().date_delivery ? new Date(doc.data().date_delivery.seconds * 1000) : null,
                    date_delete: doc.data().date_delete ? new Date(doc.data().date_delete.seconds * 1000) : null,
                }));
                let filteredUsers = users;
                if (startDate) {
                    const start = new Date(startDate);
                    filteredUsers = filteredUsers.filter(user => user.date_create?.toDate() >= start);
                }
                if (endDate) {
                    const end = new Date(endDate);
                    filteredUsers = filteredUsers.filter(user => user.date_create?.toDate() <= end);
                }
                // Reduce to the most recent entry per SKU, ensuring SKU comparison is case-insensitive
                const latestPerSKU = filteredUsers.reduce((acc, user) => {
                    const skuKey = user.sku.toLowerCase();  // Convert SKU to lowercase for consistent comparison
                    const existing = acc[skuKey];
                    if (!existing || existing.date_create.toDate() < user.date_create.toDate()) {
                        // Update the existing record with fields from the most recent one
                        acc[skuKey] = {
                            ...existing,  
                            ...user,     
                            ido: user.ido,  // Actualizamos con el nuevo 'ido'
                            idu: user.idu,  // Actualizamos con el nuevo 'idu'
                            orden_id: user.orden_id,  // Actualizamos con el nuevo 'orden_id'
                            ovelocity: user.ovelocity,  // Actualizamos con la nueva 'ovelocity'
                            date_create: user.date_create,  // Actualizamos con la nueva 'date_create'
                        };
                    }
                    return acc;
                }, {});
                const finalUsers = Object.values(latestPerSKU).map(user => {
                    const end = user.date_delete || new Date(); // Use current date if date_delete is not provided
                    const start = user.date_delivery ? new Date(user.date_delivery) : null; // Check if date_delivery is provided
                    let monthsInUse = 0;
                    if (start) {
                        monthsInUse = ((end.getFullYear() - start.getFullYear()) * 12) + (end.getMonth() - start.getMonth());
                    } else {
                        monthsInUse = 0; // No months in use if date_delivery is not provided
                    }
                    user.balance = contractMonths - monthsInUse;
                    return user;
                });
                // Sorting from the oldest to the newest
                const sortedUsers = finalUsers.sort((a, b) => {
                    const dateA = a.date_create ? a.date_create.toDate() : new Date(0);
                    const dateB = b.date_create ? b.date_create.toDate() : new Date(0);
                    return dateA - dateB; 
                });
                if (isMounted) setInfo(sortedUsers);
                setLoading(false);
            } catch (error) {
                if (isMounted) setError(error);
                toast.error("Error fetching users: " + error.message);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchUsers();
        return () => { isMounted = false; };
    }, [idc,filterOrderStatus,startDate, endDate]);

    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        handler();
        return () => {
            handler.cancel();
        };
    }, [searchTerm]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const usersItems = useMemo(() => 
        info.filter(user => 
            user.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.ido.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.ovelocity.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.orden_id.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.sku.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        ), [debouncedSearchTerm, info]
    );
    
    const numberOfPages = Math.ceil(usersItems.length / itemsPerPage);
    const displayItems = usersItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info || !Array.isArray(info)) {
        return <p>No hay información disponible</p>;
    }
    return (
        <div className="equipo-admin">
            <ListStockAsign
                idc = {idc}
                compañia={compañia}
                info = {info}
                handleChange = {handleChange}
                displayItems = {displayItems}
                numberOfPages = {numberOfPages}
                currentPage = {currentPage}
                setSearchTerm = {setSearchTerm}
                searchTerm = {searchTerm}
                setFilterOrderStatus={setFilterOrderStatus}
                startDate = {startDate}
                setStartDate = {setStartDate}
                endDate= {endDate}
                setEndDate= {setEndDate}
                filterOrderStatus={filterOrderStatus}
            />
        </div>
    );
}
