import { useEffect, useState } from "react";
import "./NSectionDescription.scss";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const NSection_Description = ({ setLoading }) => {
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'E1WkmRw07z1kAJVbsMJd');
        getDoc(specificDocRef)
            .then((document) => {
                if (document.exists) {
                    const data = document.data();
                    if (isMounted && data) {
                        setInfo(data);
                    }
                } else {
                    console.log("No such document!");
                }
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                    setLoading(false);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });

        return () => { isMounted = false; };
    }, [setLoading]);

    if (error) return <p>Error: {error.message || "An error occurred"}</p>;
    return (
        <section className="section-contect">
            <div className="section-0N-contect-container">
                <div className="section-0N-img">
                    <img alt={info.title} src={info.img} className="img-0N"/>
                </div>
                <div className="section-0N-title">
                    <h1 className="title-page-nosotros" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                    <p className="subtitle-page-nosotros"> 
                        <span className="span1">Ayudar</span>a las 
                        <span className="span2">empresas</span> y sus 
                        <span className="span3">colaboradores</span> a  
                        <span className="span1">implentar,</span> de manera correcta el 
                        <span className="span2">trabajo híbrido</span> y 
                        <span className="span3">remoto</span> en sus casas y oficinas.
                    </p>
                </div>
            </div>
        </section>
    );
}
