/* eslint-disable react/jsx-no-target-blank */
import "./N_Section_1.scss"
import { useEffect, useState } from "react";
import { ImLinkedin2 } from "react-icons/im";
import { db } from '../../../../firebase/config';
import { getDoc, doc } from 'firebase/firestore';


export const N_Section_1 = ({setLoading}) => {
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'w49atltLkYku2Tu1guam');
        getDoc(specificDocRef)
            .then((document) => {
                if (document.exists) {
                    let data = document.data();
                    // Verificamos si existe el array 'team' y lo ordenamos por 'order'
                    if (data.team && Array.isArray(data.team)) {
                        data.team.sort((a, b) => parseInt(a.order) - parseInt(b.order));
                    }
                    if (isMounted) {
                        setInfo(data); // Almacenamos el objeto 'data' con el equipo ordenado
                    }
                } else {
                    console.log("No such document!");
                }
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    if (error) return <p>Error: {error.message}</p>;
    return (
        <section className="n-section">
            <div className="n-section-title">
                <h1 className="n-title-page-team"  dangerouslySetInnerHTML={{ __html: info.title }}></h1>
            </div>
            {info.team&&
                <div className="n-section-list-01">
                    {info.team.map((item, index) => (
                        <div key={index} className="n-section-item-01">
                            <div className="n-img-01">
                                <img className="n-section-02-img" alt="card-contenido" src={item.img} />
                            </div>
                            <div className="n-section-contect">
                                <div className="n-section-title">
                                    <h2 className="n-subtitle-page">{item.name}</h2>
                                    <p className="n-load-page">{item.load}</p>
                                </div>
                                <div className="n-section-rrss" >
                                    <a href={item.url} className='redes' target='_blank'>
                                        <ImLinkedin2 />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </section>
    );
}








